<template>

  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.vacancy") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :class="mode ? 'input__day' : 'input__night'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col
            :xs="24"
            :sm="12"
            class="flex-style text-right d-flex"
            style="justify-content: flex-end; gap: 10px;"
          >
            <div v-if="branches">
              <el-popover placement="bottom" width="210">
                <div class="social-buttons">
                  <el-button
                    type="primary"
                    v-for="branch in branches"
                    class="copy-link m-0"
                    @click="copyBranchLink(branch.id)"
                    :key="'branch' + '_' + branch.id"
                  >
                    {{ branch.name }}
                  </el-button>
                </div>
                <el-button slot="reference" type="primary" class="copy-link ">
                  {{ $t("message.open_vacancy") }}
                </el-button>
              </el-popover>
            </div>
            <crm-create-and-column-settings
              :v_can="'vacancies.create'"
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = true"
              :columns="columns"
              class="mbtop5"
              @c-change="updateColumn"
              :v_can_create="'vacancies.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
          v-loading="loadingData"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.gender_ids.show">
                {{ columns.gender_ids.title }}
              </th>

              <th v-if="columns.company_id.show">
                {{ columns.company_id.title }}
              </th>
              <th v-if="columns.branch_id.show">
                {{ columns.branch_id.title }}
              </th>

              <th v-if="columns.department_ids.show">
                {{ columns.department_ids.title }}
              </th>

              <th v-if="columns.requirement_ids.show">
                {{ columns.requirement_ids.title }}
              </th>

              <th v-if="columns.min_age.show">
                {{ columns.min_age.title }}
              </th>

              <th v-if="columns.min_wage.show">
                {{ columns.min_wage.title }}
              </th>

              <th v-if="columns.currency_ids.show">
                {{ columns.currency_ids.title }}
              </th>

              <th v-if="columns.priority_id.show">
                {{ columns.priority_id.title }}
              </th>

              <th v-if="columns.url.show">
                {{ $t("message.status") }}
              </th>
              <th v-if="columns.url.show">
                {{ columns.url.title }}
              </th>
              <th v-if="columns.limit.show">
                {{ columns.limit.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.name.show">
                <crm-input
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>

              <th v-if="columns.gender_ids.show">
                <select-gender
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.gender_ids"
                ></select-gender>
              </th>

              <th v-if="columns.company_id.show">
                <select-company
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.company_id"
                ></select-company>
              </th>
              <th v-if="columns.branch_id.show">
                <select-branch
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.branch_id"
                ></select-branch>
              </th>

              <th v-if="columns.department_ids.show">
                <select-department
                  v-model="filterForm.department_ids"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-department>
              </th>

              <th v-if="columns.requirement_ids.show">
                <select-requirement
                  v-model="filterForm.requirement_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-requirement>
              </th>

              <th v-if="columns.min_age.show">
                <crm-input
                  :placeholder="columns.min_age.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.min_age"
                ></crm-input>
              </th>

              <th v-if="columns.min_wage.show">
                <crm-input
                  :placeholder="columns.min_wage.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.min_wage"
                ></crm-input>
              </th>

              <th v-if="columns.currency_ids.show">
                <select-currency
                  v-model="filterForm.currency_ids"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-currency>
              </th>

              <th v-if="columns.priority_id.show">
                <select-priority
                  v-model="filterForm.priority_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></select-priority>
              </th>
              <th v-if="columns.url.show">
                <el-select
                  :placeholder="$t('message.status')"
                  class="d-block"
                  v-model="filterForm.status"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :size="'small'"
                  filterable
                  clearable
                >
                  <el-option :label="'Открыто'" :value="1"></el-option>
                  <el-option :label="'Закрыто'" :value="2"></el-option>
                </el-select>
              </th>
              <th v-if="columns.url.show">
                <crm-input
                  :placeholder="columns.url.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.uuid"
                ></crm-input>
              </th>
              <th v-if="columns.limit.show">
                <crm-input
                  :placeholder="columns.limit.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.limit"
                  :disabled="true"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr
              class="cursor-pointer"
              @dblclick="crmCandidate(vacancy)"
              v-for="vacancy in list"
              :key="vacancy.id"
            >
              <td v-if="columns.id.show">{{ vacancy.id }}</td>

              <td v-if="columns.name.show">
                <div style="color: #409eff" @click="crmCandidate(vacancy)">
                  {{ $translateKey(vacancy.name) }}
                </div>
              </td>
              <td v-if="columns.gender_ids.show && vacancy.genders">
                <div
                  v-for="(gender, genderIndex) in vacancy.genders"
                  :key="'gender' + genderIndex"
                >
                  {{ gender.name }}
                </div>
              </td>

              <td v-if="columns.company_id.show">
                {{ vacancy.company ? vacancy.company.name : null }}
              </td>
              <td v-if="columns.branch_id.show">
                {{ vacancy.branch ? vacancy.branch.name : null }}
              </td>

              <td v-if="columns.department_ids.show && vacancy.departments">
                <div
                  v-for="(department, departmentIndex) in vacancy.departments"
                  :key="'department' + departmentIndex"
                >
                  {{ department.name }}
                </div>
              </td>

              <td v-if="columns.requirement_ids.show">
                <div
                  v-for="(requirement,
                  requirementIndex) in vacancy.requirements"
                  :key="'requirement' + requirementIndex"
                >
                  {{ requirement.name }}
                </div>
              </td>

              <td v-if="columns.min_age.show">
                {{ vacancy.min_age }} - {{ vacancy.max_age }}
              </td>

              <td v-if="columns.min_wage.show">
                {{ vacancy.min_wage }} - {{ vacancy.max_wage }}
              </td>

              <td v-if="columns.currency_ids.show && vacancy.currencies">
                <div
                  v-for="(currency, currencyIndex) in vacancy.currencies"
                  :key="'currency' + currencyIndex"
                >
                  {{ currency.name }}
                </div>
              </td>

              <td v-if="columns.priority_id.show">
                {{ vacancy.priority ? vacancy.priority.name : null }}
              </td>
              <td v-if="columns.priority_id.show">
                {{
                  vacancy.status && vacancy.status.code == 1
                    ? "Открыто"
                    : "Закрыто"
                }}
              </td>

              <td v-if="columns.url.show">
                <div
                  v-if="
                    socials &&
                      columns.url.show &&
                      (vacancy.status ? vacancy.status.code == 1 : false)
                  "
                >
                  <el-popover placement="bottom" width="210">
                    <div class="social-buttons">
                      <el-button
                        type="primary"
                        v-for="social in socials"
                        class="copy-link m-0"
                        @click="copyLink(vacancy.uuid, social.id)"
                        :key="vacancy.id + '_' + social.id"
                      >
                        {{ social.name }}
                      </el-button>
                    </div>
                    <el-button
                      slot="reference"
                      type="primary"
                      class="copy-link "
                      >{{ $t("message.copyUrl") }}</el-button
                    >
                  </el-popover>
                </div>
                <div v-else>{{ $t("message.vacancyNotAvailable") }}</div>
              </td>
              <td v-if="columns.limit.show">
                <div class="d-flex">
                  <el-tooltip
                    class="item "
                    effect="dark"
                    content="Top Left prompts info"
                    placement="top"
                  >
                    <el-button type="primary">{{ vacancy.limit }}</el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Top Left prompts info"
                    placement="top"
                  >
                    <el-button type="warning">{{
                      countVacancyFalse(vacancy.candidate_count)
                    }}</el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Top Left prompts info"
                    placement="top"
                  >
                    <el-button type="success">{{
                      countVacancyTrue(vacancy.candidate_count)
                    }}</el-button>
                  </el-tooltip>
                </div>

                <!-- (
                {{ vacancy.limit }} /
                <span class="text-warning"
                  >{{ countVacancyFalse(vacancy.candidate_count) }} /
                </span>
                <span class="text-success">{{
                  countVacancyTrue(vacancy.candidate_count)
                }}</span>
                ) -->
              </td>

              <td v-if="columns.created_at.show">
                {{ vacancy.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ vacancy.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="vacancy"
                  :actions="actions"
                  :permissionShow="'vacancies.update'"
                  :permissionDestroy="'vacancies.destroy'"
                  @edit="edit"
                  @delete="destroy"
                ></crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>

        <el-drawer
          :with-header="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>

        <el-drawer
          :wrapperClosable="false"
          :with-header="false"
          :visible.sync="drawerCandidate"
          size="90%"
          ref="drawerCandidate"
          @opened="drawerOpened('drawerCandidateChild')"
          @closed="drawerClosed('drawerCandidateChild')"
        >
          <crm-candidates
            :selectedItem="selectedItem"
            :vacancy="selectedItem"
            ref="drawerCandidateChild"
            drawer="drawerCandidate"
          ></crm-candidates>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import CrmCandidates from "./components/crm-candidates";
import selectGender from "@/components/filters/inventory/select-gender";
import selectBrench from "@/components/filters/inventory/select-branch";
import selectCompany from "@/components/filters/inventory/select-company";
import selectDepartment from "@/components/filters/inventory/select-department";
import selectRequirement from "@/components/filters/inventory/select-requirement";
import selectPriority from "@/components/filters/inventory/select-priority";
import selectPosition from "@/components/filters/inventory/select-position";
import selectGraphic from "@/components/filters/inventory/select-graphic";
import selectEmploymentType from "@/components/filters/inventory/select-employmentType";
import selectLanguage from "@/components/filters/inventory/select-language";
import selectSkill from "@/components/filters/inventory/select-skill";
import selectCurrency from "@/components/filters/inventory/select-currency";
import selectRegion from "@/components/filters/inventory/select-region";
import selectDistrict from "@/components/filters/inventory/select-district";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "VacancyController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    CrmCandidates,
    selectGender,
    selectBrench,
    selectDepartment,
    selectRequirement,
    selectPriority,
    selectPosition,
    selectGraphic,
    selectEmploymentType,
    selectLanguage,
    selectSkill,
    selectCurrency,
    selectRegion,
    selectDistrict,
    selectCompany,
  },
  data() {
    return {
      drawerCandidate: false
    };
  },
  mounted() {
    this.updateInventory();
    this.updateBranchInventory();
  },
  computed: {
    ...mapGetters({
      socials: "social/inventory",
      branches: "branch/inventory",
      list: "vacancy/list",
      columns: "vacancy/index_columns",
      pagination: "vacancy/pagination",
      statues: "vacancy/statues",
      filter: "vacancy/filter",
      sort: "vacancy/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "vacancy/index",
      updateInventory: "social/inventory",
      updateBranchInventory: "branch/inventory",
      setPagination: "vacancy/setPagination",
      updateSort: "vacancy/updateSort",
      updateFilter: "vacancy/updateFilter",
      updateColumn: "vacancy/updateColumn",
      updatePagination: "vacancy/updatePagination",
      show: "vacancy/show",
      empty: "vacancy/empty",
      delete: "vacancy/destroy",
      refreshData: "vacancy/refreshData"
    }),
    crmCandidate(model) {
      let route = this.$router.resolve({
        name: "vacancy_show",
        params: {
          id: model.id
        }
      });

      window.open(route.href, "_blank");
    },
    countVacancyTrue(val) {
      return val.filter(va => va == true).length;
    },
    countVacancyFalse(val) {
      return val.filter(va => va == false).length;
    },
    copyLink(uuid, social) {
      const fullUrlVacancy =
        window.location.origin +
        "/applicationCandidates/" +
        uuid +
        "?social=" +
        social;
      navigator.clipboard.writeText(fullUrlVacancy);
      return this.$notify({
        title: "Успешно ",
        type: "success",
        offset: 7,
        message: "Вакансия скопирована!"
      });
    },
    copyBranchLink(social) {
      const fullUrlVacancy = window.location.origin + "/open-vacancy/" + social;
      navigator.clipboard.writeText(fullUrlVacancy);
      return this.$notify({
        title: "Успешно ",
        type: "success",
        offset: 7,
        message: "Вакансия скопирована!"
      });
    }
  }
};
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.copy-link {
  font-size: 12px;
  padding: 9px 6px;
}
.social-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}
</style>
