<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{ $t("message.add_candidates") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'null'"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div>
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
            <el-row :gutter="20">
              <el-col :span="8">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.nameGiven") }}:</strong
                  >
                  <span>{{ vacancy.name }}</span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.genders">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.gender") }}:</strong
                  >
                  <span
                    v-for="(gender, index) in vacancy.genders"
                    :key="'gender-' + index"
                  >
                    {{ gender.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.age") }}:</strong
                  >
                  <span>
                    {{ vacancy.min_age + "-" + vacancy.max_age }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.department") }}:</strong
                  >
                  <span>
                    {{ vacancy.branch ? vacancy.branch.name : null }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.departments">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.branch") }}:</strong
                  >
                  <span
                    v-for="(department, index) in vacancy.departments"
                    :key="'index' + index"
                  >
                    {{ department.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.priority">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.priority") }}:</strong
                  >
                  <span>
                    {{ vacancy.priority ? vacancy.priority.name : null }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.position">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.position") }}:</strong
                  >
                  <span>
                    {{ vacancy.position ? vacancy.position.name : null }}
                  </span>
                </p>
              </el-col>

              <el-col :span="8" v-if="vacancy.graphics">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.graphic") }}:</strong
                  >
                  <span
                    v-for="(graphic, index) in vacancy.graphics"
                    :key="'index' + index"
                  >
                    {{ graphic.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.languages">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.language") }}:</strong
                  >
                  <span
                    v-for="(language, index) in vacancy.languages"
                    :key="'index' + index"
                  >
                    {{ language.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.min_wage || vacancy.max_wage">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.salary") }}:</strong
                  >
                  <span>
                    {{ vacancy.min_wage + " - " + vacancy.max_wage }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.skills">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.skill") }}:</strong
                  >
                  <span
                    v-for="(skill, index) in vacancy.skills"
                    :key="'index' + index"
                  >
                    {{ skill.skill }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.region">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.region_") }}:</strong
                  >
                  <span>
                    {{ vacancy.region.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.description">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.description") }}:</strong
                  >
                  <span>
                    {{ vacancy.description }}
                  </span>
                </p>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="app-modal__body p-5 pb-0" >
          <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
            <h2> {{ $t('message.candidates') }}</h2>
            <el-row :gutter="20">
                <el-col :span="8" v-for="(candidates, index) in list" :key="'candidates'+index">
                  <div class="dragn-item" :class="index">
                    <el-badge :value="candidates.length"  class="dragn-item-header">
                          <h3 class="dragn-item-title">{{ $t('message.candidate_'+index) }}</h3>
                      </el-badge>
                    <div class="dragn-item-main">
                      <draggable class="list-group" :list="candidates" group="people" @change="log($event, index)">
                        <div class="timeline-items__right user rounded-sm w-100"  v-for="(candidate) in candidates"
                        :key="candidate.name"  :class="mode ? 'table__myday' : 'table__mynight'">
                          <div class="info-user-left3" :class="mode ? '' : 'performance__itemnight'">
                            <div class="candidate_point" @click="showInfoList(candidate.id)">{{candidate.point}}</div>
                            <div class="img">
                              <img
                                :src="
                                  candidate.image
                                    ? baseUrl + candidate.image.path
                                    : 'img/avatar.jpeg'
                                "
                                alt=""
                              />
                            </div>
                            <div class="name-info-user-left">
                              <div class="name_u">
                                <b>{{ $t('message.fio') }}: </b>{{ candidate.name + " " + candidate.last_name }}
                              </div>
                              <div class="position_u">
                                <b>{{ $t('message.gender') }}: </b>{{ candidate.gender?candidate.gender.name:'' }}
                              </div>
                              <div class="position_u">
                                <b>{{ $t('message.phone_number') }}: </b>{{ candidate.phone_number?candidate.phone_number:'' }}
                              </div>
                              <div class="position_u">
                                <b>{{ $t('message.nation') }}: </b>{{ candidate.nation?candidate.nation.nationality:'' }}
                              </div>
                              <div class="position_u">
                                <b>{{ $t('message.date_of_birth') }}: </b>{{ candidate.date_of_birth }}
                              </div>

                            </div>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </div>                 
                </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        :append-to-body="true"
        size="70%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create-candidate
          :vacancy="vacancy"
          ref="drawerCreateChild"
          drawer="drawerCreate"
        ></crm-create-candidate>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :visible.sync="drawerUpdate"
        :append-to-body="true"
        size="70%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update-candidate
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update-candidate>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerCreateStaff"
        size="95%"
        :append-to-body="true"
        ref="drawerCreateStaff"
        @opened="drawerOpened('drawerCreateStaffChild')"
        @closed="drawerClosed('drawerCreateStaffChild')"
        >
        <crm-staff-create-new
          :candidate_id="selectedCandidate"
          ref="drawerCreateStaffChild"
          drawer="drawerCreateStaff"
        ></crm-staff-create-new>
      </el-drawer>
      <el-drawer
        title="I'm outer Drawer"
        :visible.sync="showInfoModal"
        size="40%"
        :with-header="false"
        ref="show-info"
        :append-to-body="true"
        @opened="drawerOpened('show-info')">
        <div>
          <show-info
            :candidate="selectedCandidate"
            @closeModal="closeModal"
            :child="child"
            ref="show-info"
          ></show-info>
        </div>
      </el-drawer>
    </div>
   
  </div>
</template>
<script>
import CrmCreateCandidate from "../../candidates/components/crm-create.vue";
import crmUpdateCandidate from "../../candidates/components/crm-update.vue";
import selectGender from "@/components/filters/inventory/select-gender";
import selectStatus from "@/components/filters/inventory/select-status";
import CrmStaffCreateNew from "../../candidates/components/crm-staff-create-new";
import showInfo from "../../candidates/components/show-info.vue";

import drawer from "@/utils/mixins/drawer";
import draggable from "vuedraggable";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [drawer],
  name: "VacancyController",
  components: {
    CrmCreateCandidate,
    selectGender,
    selectStatus,
    crmUpdateCandidate,
    CrmStaffCreateNew,
    draggable,
    showInfo
  },
  props: {
    vacancy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInfoModal: false,
      drawerCreate: false,
      drawerShow: false,
      drawerUpdate: false,
      filterForm: {},
      loadingData: false,
      excel_data: [],
      excel_fields: {},
      checkAll: false,
      selectedItems: [],
      selectedItem: null,
      reloadList: false,
      loadingComments: false,
      drawerCreateStaff: false,
      selectedCandidate: null,
      child:{},
    };
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updatePagination({ key: "page", value: 1 });
        await this.updateFilter(newVal);
        this.debouncedFetchData();
      },
      deep: true,
      immediate: true,
    },
    sort: {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    "pagination.page": {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    "pagination.per_page": {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    // this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  mounted() {
    if (_.isFunction(this.controlExcelData)) {
      this.controlExcelData();
    }
  },
  computed: {
    ...mapGetters({
      list: "vacancy/vacancy_candidate_list",
      columns: "candidate/columns",
      pagination: "vacancy/candidate_pagination",
      statues: "vacancy/statues",
      filter: "vacancy/filter",
      sort: "vacancy/sort",
      mode:'MODE'
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "vacancy/candidate",
      updateSort: "vacancy/updateSort",
      updateFilter: "vacancy/updateFilter",
      updateColumn: "vacancy/updateColumn",
      updatePagination: "vacancy/updateCandidatePagination",
      show: "vacancy/show",
      empty: "vacancy/empty",
      delete: "candidate/destroy",
      refreshData: "vacancy/refreshData",
      updateCandidate:"vacancy/updateCandidate"
    }),
    afterOpen() {
      this.fetchData();
    },
    crmCandidate(model) {
      this.drawerCandidate = true;
    },
    closeDrawer(drawer) {
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        // this.fetchData();
        this.afterFetchData();
      }
      if (_.isFunction(this.empty)) {
        this.empty();
      }
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
    listChanged() {
      this.reloadList = true;
    },
    showInfoList(candidate_id) {
          this.showInfoModal = true;
          this.child.candidate_id = candidate_id;
    },
    closeModal(val) {
      this.showInfoModal = val;
    },
    afterFetchData() {
      this.reloadList = false;
    },
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (this.vacancy.id) {
        this.loadingData = true;
        this.updateList({ vacancy_id: this.vacancy.id, params: query })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedItem = model;
      this.drawerUpdate = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          alert(err);
        });
    },
    addStaff(id) {
      this.selectedCandidate = id;
      this.drawerCreateStaff = true;
    },
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt, index) {
      if(evt.added){
        let data = {
          vacancy_id: this.vacancy.id,
          candidate_id: evt.added.element.id,
          step_status:index
        }
        this.loadingData = true;

        this.updateCandidate(data)
        .then((res) => {
          // this.$alert(res);

        })
        .catch((err) => {
          // alert(err);

        });
      }else if(evt.moved){

      }
    },
    handleChange() {
    },
    inputChanged(value) {
      this.activeNames = value;
    },
    
  },
};
</script>
<style lang="scss" >
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
.dragn-item{
    .timeline-items__right.user {
      margin-bottom: 15px;
      padding: 15px;
      .company_u{
        margin-bottom: 3px;
        font-size: 14px;
      }
      .info-user-left3 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        .candidate_point{
          position: absolute;
          width:30px;
          height: 30px;
          border-radius: 50%;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-weight: 600;
          color: #fff;
          line-height: 0;
          cursor: pointer;
        }
        .img {
          margin-right: 10px;
          img {
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 10%;
          }
        }
      }
      .name_u {
        font-weight: bold;
        font-size: 13px;
      }
      .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
      }
      .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
      }
    }
    padding: 0;
    .dragn-item-header{
      display: block;
      width: 100%;
      padding: 15px 20px;
      background: #409eff;
      border-radius: 8px 8px 0px 0px;
      .dragn-item-title{
        margin: 0;
        color: #fff;
        font-size: 16px;
      }
    }
    .dragn-item-main{
      padding: 15px 18px;
      border: 2px solid #409eff;
      border-radius: 0px 0px 5px 5px;
    
    }
    &.new {
      .timeline-items__right{
        box-shadow: 0px 0px 5px -1px #409eff !important;
      }
      .dragn-item-header{
        background: #409eff;
      }
      .dragn-item-main{
        border: 2px solid #409eff;
      }
      .candidate_point{
        background: #409eff;
      }
     
    }
    &.called_interview{
      .timeline-items__right.user {
        box-shadow: 0px 0px 5px -1px #cf7f18 !important;
        border-color: #cf7f18;
      }
      .dragn-item-header{
        background: #cf7f18;
      }
      .dragn-item-main{
        border: 2px solid #cf7f18;
      }
      .candidate_point{
        background: #cf7f18;
      }
    }
    &.approved_employment{
      .timeline-items__right.user {
        box-shadow: 0px 0px 5px -1px #11a956 !important;
        border-color: #11a956;
      }
      .dragn-item-header{
        background: #11a956;
      }
      .dragn-item-main{
        border: 2px solid #11a956;
      }
      .candidate_point{
        background: #11a956;
      }
    }
}

</style>
